import React, { Fragment } from 'react'
import { Periods } from '@commonstock/common/src/types'
import { useGetTopMemos, MemoFeedItem } from '@commonstock/common/src/api/feed'
import { SpacerV } from '../../composing/Spacing'
import { DiscoverTopMemosSkeleton } from './Discover.skeleton'
import { SectionCard } from '../../components/styles'
import ListRowHeader from '../../components/ListRowHeader'
import MemoCardSmall from '../memo/MemoCardSmall'
import { SkeletonWrapper } from '../../components/SuspenseSkeleton'

export function DiscoverTopMemos() {
  const [topMemosPages, , , , , firstPageLoaded] = useGetTopMemos({ query: { period: Periods['1W'], limit: 3 } })

  const topMemos: MemoFeedItem[] | undefined = topMemosPages?.flatMap(item => item.items)

  return (
    <SkeletonWrapper
      pending={!firstPageLoaded}
      skeleton={<DiscoverTopMemosSkeleton />}
      failed={topMemos && !topMemos.length}
    >
      {!!topMemos?.length && (
        <SectionCard>
          <ListRowHeader title={'Top memos'} />
          {topMemos.map(memo => (
            <Fragment key={memo.uuid}>
              <SpacerV />
              <MemoCardSmall memoFeedItem={memo} />
            </Fragment>
          ))}
        </SectionCard>
      )}
    </SkeletonWrapper>
  )
}
